import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { buyModalHide } from "../../redux/counterSlice";
import { getListing } from "../../services/services";
import Image from "next/image";
import { roundToDecimalPlaces } from "../../utils/basicFunctions";
import useTransactionFunctions from "../../utils/transactionFunctions";
import DisableShield from "../loading/DisableShield";

const BuyModal = () => {
  const { handleDirectBuy } = useTransactionFunctions();

  const { buyModal, buyModalData } = useSelector((state) => state.counter);
  const { currToUsdRate } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  // const [checkTerms, setCheckTerms] = useState(false);
  const [loader, setLoader] = useState(false);
  const tokenId = buyModalData?.tokenId;
  const image = buyModalData?.image;
  const name = buyModalData?.name;
  const supply = buyModalData?.supply;
  const royalty = buyModalData?.royalty;
  const ownerWallet = buyModalData?.ownerWallet;
  const userWallet = buyModalData?.userWallet;

  const [initialPrice, setInitialPrice] = useState(0);
  const [listedAmount, setListedAmount] = useState(0);

  // Convert ETH to USD

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await handleDirectBuy(
      tokenId,
      ownerWallet,
      initialPrice,
      royalty,
      listedAmount,
      userWallet
    );
    setLoader(false);
  };

  const NftListings = async (pid, ownerAddress) => {
    console.log("Inside NftListings function");
    console.log("PID:", pid, "Type:", typeof pid);
    console.log("Owner Address:", ownerAddress, "Type:", typeof ownerAddress);
    try {
      const response = await getListing(pid, ownerAddress);
      console.log("Response from getListing:", response);
      if (response.isListed) {
        console.log("Listings response from contract:", response);
        setInitialPrice(parseFloat(response.price) / 1e18);
        setListedAmount(response.amount);
      } else {
        console.log("Not listed");
      }
    } catch (error) {
      console.error("Error fetching NFT listings:", error);
    }
  };

  useEffect(() => {
    if (tokenId !== undefined && ownerWallet !== undefined) {
      NftListings(tokenId, ownerWallet);
    }
  }, [tokenId, ownerWallet]);

  return (
    <div>
      {/* Buy Now Modal */}

      <div className={buyModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                Complete checkout
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(buyModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* Body */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-russo text-jacarta-700 text-sm dark:text-white">
                  Items: {supply}
                </span>
                <span className="font-russo text-jacarta-700 text-sm dark:text-white">
                  Subtotal
                </span>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4">
                <figure className="mr-5 self-start">
                  <Image
                    width={150}
                    height={150}
                    src={image ? image : "/logo.png"}
                    alt="avatar 2"
                    className="rounded-2lg w-[150px] h-auto"
                    loading="lazy"
                  />
                </figure>

                <div>
                  {/* <a href="collection.html" className="text-accent text-sm">
                    Elon Musk #709
                  </a> */}
                  <h3 className="font-russo text-jacarta-700 mb-1 text-base dark:text-white">
                    {name}
                  </h3>
                  <div className="flex flex-wrap items-center">
                    <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                      Creator Earnings: {royalty}%
                    </span>
                    <span data-tippy-content="The creator of this collection will receive 5% of the sale total from future sales of this item.">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="dark:fill-jacarta-300 fill-jacarta-700 h-4 w-4"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="ml-auto">
                  <span className="mb-1 flex items-center whitespace-nowrap">
                    <span data-tippy-content="ETH">
                      {/* <svg className="h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-ETH"></use>
                      </svg> */}
                      <Image
                        width={20}
                        height={20}
                        src={"/images/chains/eth-icon.svg"}
                        alt="ETH"
                      />
                    </span>
                    <span className="text-green font-medium tracking-tight">
                      {initialPrice || 0.0} ETH
                    </span>
                  </span>
                  <div className="dark:text-jacarta-300 text-right text-sm">
                    ${roundToDecimalPlaces(initialPrice * currToUsdRate, 2)}
                  </div>
                </div>
              </div>
              {/* Terms */}
              {/* <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="buyNowTerms"
                  value={checkTerms}
                  onChange={() => setCheckTerms(!checkTerms)}
                  className="checked:bg-accent dark:bg-[#1e1e1e] text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                />
                <label
                  htmlFor="buyNowTerms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"Nftos's"}{" "}
                  <Link href="/tarms" className="text-accent">
                    Terms of Service
                  </Link>
                </label>
              </div> */}
            </div>
            {/* end body */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {loader ? (
                  <div>
                    <DisableShield />
                    <div className="spin-loader"></div>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center text-white transition-all"
                    onClick={handleSubmit}
                  >
                    Confirm Checkout
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyModal;
