import { ethers } from "ethers";
import mintAbi from "./mintAbi.json";
import data from "./address.json";

const ContractAddress = data.mintAddress;
const ContractAbi = mintAbi.mintAbi;
const ercAddress = data.address;
// const date = new Date('Tue, 10 Sep 2024 21:12:04 GMT');
// const options = { timeZone: 'Asia/Kolkata', timeZoneName: 'short' };
// console.log(date.toLocaleString('en-IN', options));

const getChecked = async () => {
  const provider = new ethers.BrowserProvider(ethereum);
  const network = await provider.getNetwork();
  const chainId = network.chainId.toString(); // Convert to string
  if (chainId !== "11155111") {
    // Compare as a string
    alert("Please switch to the Sepolia Testnet (chainId: 11155111).");
    return false;
  } else {
    return true;
  }
};

const getEthereumContract = async () => {
  const provider = new ethers.BrowserProvider(ethereum);
  const accounts = await provider.send("eth_requestAccounts", []);
  const signer = await provider.getSigner();
  if (getChecked()) {
    const transactionContract = new ethers.Contract(
      ContractAddress,
      ContractAbi,
      signer
    );
    return transactionContract;
  }
};

const checkWallet = async (walletAddress) => {
  const provider = new ethers.BrowserProvider(ethereum);
  const accounts = await provider.send("eth_requestAccounts", []);
  if (
    accounts[0].toLowerCase() != walletAddress.toLowerCase() &&
    getChecked()
  ) {
    return false;
  } else {
    return true;
  }
};
const setApprovalForAll = async (walletAddress) => {
  try {
    console.log("in nfts", walletAddress);
    const provider = new ethers.BrowserProvider(ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    if (accounts[0]?.toLowerCase() != walletAddress?.toLowerCase()) {
      return "connect the address wallet";
    }
    const contract = await getEthereumContract();
    if (!contract) {
      return "Contract not found.";
    }
    const response = await contract.isApprovedForAll(walletAddress, ercAddress);
    console.log(response); // true or false
    if (!response) {
      const tx = await contract.setApprovalForAll(ercAddress, "true");
      console.log("successfully approved!");
      return "approved";
    } else {
      console.log("already approved");
      return "approved";
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

const mintToken = async (amount, dataHash) => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }

    console.log("Calling mint with:", amount, dataHash);
    console.log("mint:");
    const tx = await contract.mint(amount, dataHash);
    console.log("Transaction response:", tx);
    console.log("print receipt:");
    const receipt = await tx.wait();
    console.log("Done");
    console.log("Transaction receipt:", receipt);
    const logs = receipt.logs;
    // const eventInterface = contract.interface.getEvent("TokenMinted");
    const event = logs
      .map((log) => {
        try {
          return contract.interface.parseLog(log);
        } catch (error) {
          return null;
        }
      })
      .find((parsedLog) => parsedLog && parsedLog.name === "TokenMinted");
    if (event) {
      const tokenId = event.args.tokenId.toString();
      console.log(`Token minted with ID: ${tokenId}`);
      return tokenId;
    } else {
      console.error("TokenMinted event not found in receipt.");
      return "Failed to extract tokenId.";
    }
  } catch (error) {
    // console.error("Error in mintToken:", error);
    // if (error.code === "UNSUPPORTED_OPERATION") {
    //   console.error("Operation not supported:", error);
    // }
    // reportError(error);
    // window.alert(error?.reason || "Error occurred while minting token.");
    alert("Error Occured while minting token! Please Try Again");
  }
};

const getTokenIDs = async () => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }
    const tx = await contract.allTokenIds();
    console.log(tx);
    return tx;
  } catch (error) {
    console.error("Error in getTokenIDs:", error);

    window.alert(
      error?.reason ||
        "Error occurred while getting NFTs.. Try connecting your wallet and refresh the page."
    );
  }
};

const updateTokenUri = async (token_id, newBaseUri) => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }

    const tx = await contract.updateUri(token_id, newBaseUri);
    await tx.wait(); // Wait for the transaction to be mined
    return "Base URI updated successfully";
  } catch (error) {
    console.error("Error in updateTokenUri:", error);

    window.alert(error?.reason || "Error occurred while updating token URI.");
  }
};

const getTokenSupply = async (token_id) => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }

    const supply = await contract.totalSupply(token_id);
    return supply;
  } catch (error) {
    console.error("Error in getTokenSupply:", error);

    window.alert(error?.reason || "Error occurred while getting token supply.");
  }
};

const getAllTokenIds = async () => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }
    const ids = await contract.allTokenIds();
    const formattedIds = Array.from(ids).map((id) => {
      if (id <= Number.MAX_SAFE_INTEGER) {
        return Number(id);
      }
      return id;
    });
    return formattedIds;
  } catch (error) {
    // console.log("error: ", error);
    console.error("Error in getAllTokenIds:", error);
    return [];
    // window.alert(
    //   error?.reason || "Error occurred while getting all token IDs."
    // );
  }
};

const getCreatedNFTs = async () => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract instance is not available.");
      return null;
    }
    const result = await contract.getCreatedNFTs();
    const nfts = result.map((item) => {
      const data = item.map((i) => {
        if (typeof i === "bigint") {
          return Number(i);
        }
        return i;
      });

      return {
        amount: data[0],
        uri: data[1],
        token_id: data[2],
        creator_address: data[3],
      };
    });
    // console.log("Created NFTs:", nfts);
    return nfts;
  } catch (error) {
    console.error("Error occurred while getting created NFTs:", error);
    window.alert(
      error?.reason ||
        "Error occurred while getting created NFTs. Try connecting your wallet and refresh the page"
    );
  }
};

const getBalanceOfNFT = async (token_id, owner_address) => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }
    const ids = await contract.balanceOf(owner_address, token_id);
    return ids;
  } catch (error) {
    console.log("error in getting balance of nft: ", error);
  }
};

const getBalanceOfBatch = async (owner_address) => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }
    const tokenIDs = await getAllTokenIds();
    const ownerAddressesArray = Array(tokenIDs.length).fill(owner_address);
    const balances = await contract.balanceOfBatch(
      ownerAddressesArray,
      tokenIDs
    );
    return balances;
  } catch (error) {
    console.error("Error in getBalanceOfnFT:", error);

    window.alert(
      error?.reason ||
        "Error occurred while getting all balances. Try connecting your wallet and refresh the page"
    );
  }
};

const getCreatorAddress = async (token_id) => {
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }
    const address = await contract.creatorAddress(token_id);
    return address;
  } catch (error) {
    console.error("Error in getAllTokenIds:", error);

    window.alert(
      error?.reason ||
        "Error occurred while getting all NFTs. Try connecting your wallet and refresh the page"
    );
  }
};

const getBalanceUser = async (address) => {
  console.log(address);
  try {
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }

    const data = await contract.balanceUser(address);
    // console.log("Raw proxy data:", data);
    const balancesArray = [];
    console.log(data[0]);
    for (let i = 0; i < data.length; i += 1) {
      let temp = [];
      temp.push(Number(data[i][0]), Number(data[i][1]));
      balancesArray.push(temp);
    }
    console.log("balance of user:", balancesArray);
    return balancesArray;
    //return data;
  } catch (error) {
    console.error("Error in getBalanceUser:", error);

    window.alert(
      error?.reason ||
        "Error occurred while getting NFTs Balances. Try connecting your wallet and refresh the page"
    );
  }
};

const getNFTDetails = async (token_id) => {
  try {
    // console.log(typeof token_id);
    const contract = await getEthereumContract();
    if (!contract) {
      console.error("Contract not found.");
      return null;
    }
    const data = await getCreatedNFTs(); // Fetch all NFTs

    // Filter the data for the specific token_id
    const nftDetails = data.find((nft) => nft.token_id === Number(token_id));

    if (!nftDetails) {
      console.log(`NFT with token_id ${token_id} not found.`);
      return null;
    }

    return nftDetails;
  } catch (error) {
    console.error("Error in getNFTDetails:", error);

    window.alert(
      error?.reason ||
        "Error occurred while getting NFT details. Try connecting your wallet and refresh the page"
    );
  }
};

export {
  getEthereumContract,
  getTokenIDs,
  mintToken,
  updateTokenUri,
  getTokenSupply,
  getAllTokenIds,
  getCreatedNFTs,
  setApprovalForAll,
  getBalanceOfNFT,
  getBalanceOfBatch,
  getCreatorAddress,
  getNFTDetails,
  checkWallet,
  getBalanceUser,
};
