import "../styles/globals.css";
import "../styles/index.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import { EthereumProvider } from "../context/contract";
import { WalletProvider } from "../components/wallet-btn/WalletProvider";
import { NextAuthProvider } from "../components/Providers";
// import Router from "next/router";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  const shouldNoLayout = ["/login", "/", "/signup"];

  return (
    <>
      <Meta title="home" />
      <Provider store={store}>
        <NextAuthProvider>
          <ThemeProvider enableSystem={true} attribute="class">
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
              {shouldNoLayout.includes(pid) ? (
                <main className="font-russo">
                  <Component {...pageProps} />
                </main>
              ) : (
                <WalletProvider>
                  <MetaMaskProvider>
                    <EthereumProvider>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </EthereumProvider>
                  </MetaMaskProvider>
                </WalletProvider>
              )}
            </UserContext.Provider>
          </ThemeProvider>
        </NextAuthProvider>
      </Provider>
    </>
  );
}

export default MyApp;

// protected routes
//  - /createGrocery
//  - /createProperty
//  - /createProduct
//  - /createMain
//  - /createProfile
//  - /dashboard
//  - /live-auction
// - /login
// - /maintenance
// - /nftPage
// - /profile
// - /property
// - /rankings
// - /user

// public routes
// - /404
// - /blog
// - /careers
// -/case-studies
// -/collection
// - /comingsoon
// - /contact
// - /dao
// - /faq
// - /Ecommerce
// - /help - center
// - /home
// - /item
// - /landing
// - /newsletter
// - /partners
// - /platform_status
// - /pricing
// - /prop
// /single
// -/post
// - /tarms
// -/wallet
